import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GET_SAVE_VIEW_KEY } from '../get/getSaveViewAPI';
import { ISaveView } from '../saveViewAPI';
// import { deleteFetch } from 'src/utils/fetcher';
// import { concatQuerys } from 'src/utils/url';

// const DELETE_SAVE_VIEW_URL_PATH = '/api/v2/resource-planner/save-view';
// export const DELETE_SAVE_VIEW_MSW_STATUS_KEY = 'deleteSaveViewAPI';

// const deleteSaveView = (viewId: number) =>
//   deleteFetch({
//     path: `${DELETE_SAVE_VIEW_URL_PATH}${concatQuerys([
//       { param: 'viewId', value: viewId.toString() },
//     ])}`,
//     key: DELETE_SAVE_VIEW_MSW_STATUS_KEY,
//   });

const localStorageView = 'saveView';

// Delete from localStorage, will remove when Backend is ready
const deleteSaveView = (id: number): any => {
  const viewData = localStorage.getItem(localStorageView);

  if (viewData) {
    const updateData: ISaveView[] = JSON.parse(viewData);

    const updatedViews = updateData.filter((view) => view.id !== id);
    localStorage.setItem(localStorageView, JSON.stringify(updatedViews));
  }
};

export const useDeleteSaveView = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSaveView, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries([GET_SAVE_VIEW_KEY])]),
  });
};
