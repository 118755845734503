import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GET_SAVE_VIEW_KEY } from '../get/getSaveViewAPI';
import { ISaveView } from '../saveViewAPI';

export const PUT_SAVE_VIEW_MSW_STATUS_KEY = 'putSaveViewAPI';
// import { putFetch } from 'src/utils/fetcher';

// const PUT_SAVE_VIEW_URL_PATH = '/api/v2/resource-planner/save-view';

// const updateSaveView = (updateModel: any) =>
//   putFetch({
//     path: PUT_SAVE_VIEW_URL_PATH,
//     key: PUT_SAVE_VIEW_MSW_STATUS_KEY,
//     body: JSON.stringify(updateModel),
//   });

type ISaveViewData = Omit<ISaveView, 'lastActive'>;

const localStorageView = 'saveView';

// Update to localStorage, will remove when Backend is ready
const updateSaveView = (updateModel: ISaveViewData): any => {
  const viewData = localStorage.getItem(localStorageView);

  const updateData: ISaveView[] = viewData ? JSON.parse(viewData) : [];

  const viewIndex = updateData.findIndex((view) => view.id === updateModel.id);

  if (viewIndex !== -1) {
    const updatedView = {
      ...updateData[viewIndex],
      ...updateModel,
      lastActive: new Date(),
    };

    updateData[viewIndex] = updatedView;

    localStorage.setItem(localStorageView, JSON.stringify(updateData));
  }
};

export const useUpdateSaveView = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSaveView, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SAVE_VIEW_KEY]);
    },
  });
};
