import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISaveView } from '../saveViewAPI';
import { GET_SAVE_VIEW_KEY } from '../get/getSaveViewAPI';

// import { postFetch } from 'src/utils/fetcher';

// const POST_SAVE_VIEW = '/api/v2/resource-planner/save-view';
export const POST_SAVE_VIEW_MSW_STATUS_KEY = 'postSaveViewAPI';

// const postSaveView = (data: any): Promise<any> =>
//   postFetch({
//     path: POST_SAVE_VIEW,
//     key: POST_SAVE_VIEW_MSW_STATUS_KEY,
//     body: JSON.stringify({
//       data,
//     }),
//   });

type ISaveViewData = Omit<ISaveView, 'id' | 'lastActive'>;

const localStorageView = 'saveView';

// Save to localStorage, will remove when Backend is ready
const postSaveViewLocalStorage = (data: ISaveViewData): any => {
  const viewData = localStorage.getItem(localStorageView);

  const updateData: ISaveView[] = viewData ? JSON.parse(viewData) : [];

  const newView: ISaveView = {
    ...data,
    id: updateData.length,
    lastActive: new Date(),
  };

  updateData.push(newView);

  localStorage.setItem(localStorageView, JSON.stringify(updateData));
};

export const usePostSaveView = () => {
  const queryClient = useQueryClient();

  return useMutation(postSaveViewLocalStorage, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries([GET_SAVE_VIEW_KEY])]),
  });
};
