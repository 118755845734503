// import { useQuery } from '@tanstack/react-query';
// import { getFetch } from 'src/utils/fetcher';

// const GET_SAVE_VIEW_URL_PATH = '/api/v2/resource-planner/save-view';
export const GET_SAVE_VIEW_MSW_STATUS_KEY = 'getSaveViewAPI';
export const GET_SAVE_VIEW_KEY = 'GET_SAVE_VIEW';

// const getSaveView = (): Promise<any> =>
//   getFetch({
//     path: GET_SAVE_VIEW_URL_PATH,
//     key: GET_SAVE_VIEW_MSW_STATUS_KEY,
//   });

// Get from localStorage, will remove when Backend is ready
export const useGetSaveView = () => {
  // const { data } = useQuery([GET_SAVE_VIEW_KEY], getSaveViewData);

  // const saveView = data?.entities
  //   ? data.entities.map(({ properties }: any) => ({ ...properties }))
  //   : [];

  // return {
  //   saveView,
  //   ...rest,
  // };
  const viewData = localStorage.getItem('saveView');
  return {
    saveView: viewData,
  };
};
