/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';
import { SidePanel } from 'src/components/SidePanel';
import { FilterStoreProvider } from 'src/stores/FilterStore';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { initFilterStore } from 'src/reducers/FilterReducer';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import {
  useGetSaveView,
  usePostSaveView,
  useUpdateSaveView,
  useDeleteSaveView,
} from 'src/apis/resourcePlannerAPI/saveViewAPI';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import MainContainer from './components/MainContainer';
import SideBar from './components/SideBar';

interface IFilterLayout {
  filterList?: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList?: Record<TFilterID, IFilterProps>;
  children: JSX.Element;
  viewOptions?: JSX.Element;
}

const FilterLayout = ({
  filterList = [],
  selectedFilterList = {},
  children,
  viewOptions,
}: IFilterLayout) => {
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);
  const { saveView } = useGetSaveView();
  const { mutateAsync: postSaveView } = usePostSaveView();
  const { mutateAsync: updateSaveView } = useUpdateSaveView();
  const { mutateAsync: deleteSaveView } = useDeleteSaveView();

  const saveViewPost = () => {
    console.log('SAVE VIEW');
    postSaveView({
      title: 'First View',
      period: '2',
      isDefault: false,
      filters: {
        ProjectManager: {
          label: 'ProjectManager',
          id: 5,
          values: [
            {
              label: 'Peter Summersen',
              value: '373',
            },
          ],
        },
        Employee: {
          label: 'Employee',
          id: 12,
          values: [
            {
              label: 'employee 2',
              value: 'employeeOption2',
            },
          ],
        },
      },
    });
  };

  const getViewPost = () => {
    console.log('GET VIEW', saveView);
  };

  const updateViewPost = () => {
    console.log('UPDATE VIEW');
    updateSaveView({
      id: 0,
      title: 'Updated View',
      period: '1',
      isDefault: true,
      filters: {
        ProjectManager: {
          label: 'ProjectManager',
          id: 5,
          values: [
            {
              label: 'Peter Summersen',
              value: '373',
            },
          ],
        },
        Employee: {
          label: 'Employee',
          id: 12,
          values: [
            {
              label: 'employee 2',
              value: 'employeeOption2',
            },
          ],
        },
      },
    });
  };

  const deleteViewPost = () => {
    console.log('DELETE VIEW');
    deleteSaveView(0);
  };

  return (
    <FilterStoreProvider
      value={{
        ...initFilterStore,
        filterList,
        selectedFilterList,
        filterQueryObj: selectedFilterList,
      }}
    >
      <SidePanel
        sideBar={<SideBar activateSidePanelButtonRef={activateSidePanelButtonRef} />}
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <MainContainer
          viewOptions={viewOptions}
          activateSidePanelButtonRef={activateSidePanelButtonRef}
        >
          {children}
        </MainContainer>
      </SidePanel>
      <button type="button" onClick={() => saveViewPost()}>
        SAVE VIEW
      </button>
      <button type="button" onClick={() => updateViewPost()}>
        Update VIEW
      </button>
      <button type="button" onClick={() => getViewPost()}>
        GET VIEW
      </button>
      <button type="button" onClick={() => deleteViewPost()}>
        DELETE VIEW
      </button>
    </FilterStoreProvider>
  );
};

export default FilterLayout;
